/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  margin: 0;
  overflow: hidden !important; /* Prevent scrolling */
}


/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-background-color: transparent;
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.marker-3d {
  /* Perspective to give depth */
  perspective: 600px;

  /* Transformations for a more pointed look */
  transform: rotateX(765deg) rotateY(0deg) skewX(0deg);

  /* Add shadow for depth */
  box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.4); 
  border-radius: 250px;

  /* Transform origin at the bottom to simulate the marker pointing */
  transform-origin: center bottom;

  /* Prevent blurring */
  backface-visibility: hidden;
  
  /* Ensure the circle stays sharp */
  image-rendering: crisp-edges;
  -webkit-font-smoothing: antialiased;
}


.background-image {
  width: 100vw; /* Set image width to cover viewport width */
  height: 100vh; /* Set image height to cover viewport height */
  object-fit: cover; /* Ensure the image covers the entire container */
  position: fixed; /* Position the image absolutely */
  top: 0;
  left: 0;
  z-index: -1; /* Ensure the image is behind other content */
}

ion-toolbar {
  --background: transparent !important; /* Set toolbar background to transparent */
  --ion-color-base: transparent !important; /* Set toolbar background to transparent */
}

html, body, #root, .ion-page {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden !important; /* Prevent scrolling */
}

ion-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.2);
  --border-radius: 50px;
}

.ion-modal-auction {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.2);
  --border-radius: 15px !important;
  z-index: 9999000 !important;
}

.ion-modal-bidding-auction {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.2);
  --border-radius: 15px !important;
  z-index: 9999001 !important;
  --height: auto;
}

.ion-modal-auction::part(backdrop) {
  --background: #fff !important;
}

#background-content {
  --background: rgba(255, 255, 255, 0.3) !important;
}

ion-content.no-scroll {
  > .scroll-content {
      overflow: hidden !important;
  }
}

/* Add this to your CSS file */
.shadow-gradient-bottom {
  position: relative;
  overflow: hidden;
}

.shadow-gradient-bottom::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70%; /* Adjust as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  pointer-events: none;
}

.searchbar-input {
  input{
     font-size: 14px !important;  
  }
  ion-icon {
    width: 16px !important;
  }
}

.block-bidding {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}