.restaurant-modal {
    --border-radius: 16px;
    --backdrop-opacity: 0.2;
  }
  
  .restaurant-modal::part(content) {
    border-radius: 16px;
    --background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    --padding-top: 0px;
    will-change: transform; /* Add this to optimize performance */
  }
  
  .glassmorphism {
    background: rgba(255, 255, 255, 0.2) !important;
    backdrop-filter: blur(20px); /* Reduced from 40px for performance */
    -webkit-backdrop-filter: blur(20px);
    will-change: transform; /* Optimize performance */
}
  
/* Ion Alert content white background */
.alert-wrapper {
    background: white !important;
    border-radius: 16px;
    z-index: 1000;
  }

  .toggle-button {
    position: absolute;
    top: -20px; /* Adjust this value to position the button above the modal */
    left: 16px;
    z-index: 1;
    transition: transform 0.3s ease-out;
  }
  
  .restaurant-modal {
    --height: 100%;
    --border-radius: 16px 16px 0 0;
  }
  
  .restaurant-modal::part(content) {
    padding-top: 16px;
  }
  
  /* Adjust button position when modal is fully expanded */
  .restaurant-modal.modal-default::part(content) {
    padding-top: 60px; /* Adjust this value as needed */
  }
  
  .restaurant-modal.modal-default + .toggle-button {
    transform: translateY(calc(var(--ion-safe-area-top, 0px) + 50px)); /* Adjust this value as needed */
  }
