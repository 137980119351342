.custom-bidding-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.6);
    --border-radius: 15px !important;
    z-index: 9999001 !important;
  }
  
  .custom-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: auto; /* Allows for a flexible height */
    overflow-y: auto; /* Enable scrolling for content */
  }

  
  /* Optional: Set a minimum height for the modal content */
  .custom-modal-content h2,
  .custom-modal-content h3 {
    margin: 0; /* Reset margin for headers */
  }

  /* BiddingModal.css */

/* For the selected segment button */
ion-segment-button.ion-activated {
    --background: white; /* Set background to white when selected */
    --color: black; /* Set text color to black */
    border-radius: 20px; /* Optional: adjust border radius for a nicer look */
    background-color: #fff;
    shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* For unselected buttons */
  ion-segment-button {
    --background: transparent; /* Keep unselected buttons transparent */
    --color: gray; /* Set text color for unselected buttons */
    border-radius: 20px; /* Optional: adjust border radius for a nicer look */
    --border-radius: 20px;
  }
  
  /* Optional: Additional styling to the segment itself */
  ion-segment {
    --indicator-color: transparent; /* Remove indicator if not needed */
    border: 1px solid #ccc; /* Add a border around the segment control */
    border-radius: 20px; /* Adjust the border radius */
    padding: 2px; /* Add some padding */
  }

  .ion-modal-auction::part(content) {
    --background: rgb(255, 255, 255, 0.3);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    --padding-top: 0px;
    box-shadow: none;
  }