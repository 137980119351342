.transparent-toolbar {
    --background: transparent !important; /* Set toolbar background to transparent */
    --color: #ffffff; /* Set text color for toolbar items */
    --border-style: none; /* Set border color to transparent */
  }

ion-item {
  --background: transparent; /* Set list background to transparent */
  --color: #1e1e1e; /* Set text color for list items */
  --border-style: none; /* Set border color to transparent */
}

.custom-list {
  background: rgba(255, 255, 255, 0.15); /* Set background color with opacity */
  backdrop-filter: blur(10px); /* Apply a blur filter */
  border-radius: 15px; /* Add border radius for rounded corners */
  padding: 10px; /* Add padding for spacing */
}

.custom-list ion-item {
  background: rgba(255, 255, 255, 0.15); /* Set item background color with opacity */
  border-radius: 15px; /* Add border radius for rounded corners */
  margin-bottom: 10px; /* Add margin between items */
}

ion-item {
  background: rgba(255, 255, 255, 0.15); /* Set item background color with opacity */
  border-radius: 15px; /* Add border radius for rounded corners */
  margin-bottom: 10px; /* Add margin between items */
}

ion-button {
  --background: transparent; /* Set list background to transparent */
  --color: #ffffff; /* Set text color for list items */
  --border-style: none; /* Set border color to transparent */
  padding-left: 4px; /* Add padding for spacing */
  padding-right: 4px; /* Add padding for spacing */
}

.custom-list ion-item ion-button {
  background: rgba(255, 255, 255, 0.15); /* Set item background color with opacity */
  border-radius: 15px; /* Add border radius for rounded corners */
  margin-bottom: 10px; /* Add margin between items */
}


.click-icons{
  font-size: 64px
}


.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 81px;
}

.icon {
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  border-radius: 50%; /* Ensure the icon is circular */
}




.progress-btn {
	position: relative;
	width: 100%;
	height: 32px;
	display: inline-block;
	background: #8CBC4C;
	font-family: "RobotoDraft","Roboto",sans-serif;
	color: #1e1e1e;
	font-weight: bold;
	font-size: 16px;
	transition: all 0.4s ease;
  clip-path: polygon(92% 0, 100% 50%, 92% 100%, 0% 100%, 5% 50%, 0% 0%);
  
}
.progress-btn:not(.active) {
	cursor: pointer;
}

.progress-btn .btn {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	line-height: 35px;
	text-align: center;
	z-index: 10;
	opacity: 1;
}

.progress-btn .progress {
	width: 0%;
	z-index: 5;
	background: #9EC568;
	opacity: 0;
	transition: all 0.3s ease;
}
.progress-btn.active .progress {
	opacity: 1;
	animation: progress-anim 10s infinite ease 0s;
}
.progress-btn[data-progress-style='indefinite'].active .progress {
	animation: progress-indefinite-anim 1.6s infinite linear 0s;
}

.progress-btn[data-progress-style='fill-back'] .progress {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.progress-btn[data-progress-style='indefinite'] .progress {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'50'%20height%3D'100'%20viewBox%3D'0%200%205%2010'%3E%0A%09%3Crect%20width%3D'110%25'%20x%3D'-5%25'%20y%3D'-5%25'%20height%3D'110%25'%20fill%3D'transparent'%2F%3E%0A%09%3Cline%20x1%3D'-2'%20y1%3D'1'%20x2%3D'7'%20y2%3D'10'%20stroke%3D'%239EC568'%20stroke-width%3D'2'%2F%3E%0A%09%3Cline%20x1%3D'-2'%20y1%3D'6'%20x2%3D'7'%20y2%3D'15'%20stroke%3D'%239EC568'%20stroke-width%3D'2'%2F%3E%0A%09%3Cline%20x1%3D'-2'%20y1%3D'-4'%20x2%3D'7'%20y2%3D'5'%20stroke%3D'%239EC568'%20stroke-width%3D'2'%2F%3E%0A%3C%2Fsvg%3E");
}


@keyframes dash {
	0% {
		stroke-dasharray: 1,95;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 85,95;
		stroke-dashoffset: -25;
	}
	100% {
		stroke-dasharray: 85,95;
		stroke-dashoffset: -93;
	}
}

@keyframes rotate {
	0% {transform: rotate(0deg); }
	100% {transform: rotate(360deg); }
}

@keyframes progress-anim {
	0% {
		width: 0%;
	}
	5% {
		width: 0%;
	}
	10% {
		width: 15%;
	}
	30% {
		width: 40%;
	}
	50% {
		width: 55%;
	}
	80% {
		width: 100%;
	}
	95% {
		width: 100%;
	}
	100% {
		width: 0%;
	}
}

@keyframes progress-indefinite-anim {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 0%;
	}
}