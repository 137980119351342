.modal-handle {
    display: none !important;
}

.business-modal {
    --backdrop-opacity: 0.2;
    z-index: 500;
  }
  
  .business-modal::part(content) {
    --background: rgb(255, 255, 255, 0.6);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    --padding-top: 0px;
    box-shadow: none;
  }

  
  .custom-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .button-container {
    padding: 16px 16px 8px;
    background: transparent;
    z-index: 9999 !important;
    height: 60px; /* Adjust this value to create the desired transparent top bar */
  }
  
  .toggle-button {
    --padding-top: 8px;
    --padding-bottom: 8px;
    --padding-start: 8px;
    --padding-end: 8px;
  }
  
  .custom-modal {
    background: transparent;
    flex-grow: 1;
    display: flex;
    padding: -25px;
    flex-direction: column;
  }
  
 
  
  .glassmorphism-content {
    flex-grow: 1;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 16px 16px 0 0;
    padding: 16px;
    overflow-y: auto;
    margin-top: 10px; /* Adjust this value to create space between the handle and content */
  }
  
/* Ion Alert content white background */
.alert-wrapper {
    background: white !important;
    border-radius: 16px;
    z-index: 1000;
  }


  .custom-list {
    margin: 0;
    padding: 0;
    border-radius: 0px; 
  }

  
  
  .custom-item {
    --min-height: 30px;
    --padding-start: 16px;
    --padding-end: 16px;
    --inner-padding-end: 0;
    --border-radius: 0px;
    --margin-bottom: 0px;
    border-bottom: 1px solid #e0e0e0;
  }

  .custom-item:hover {
    background: #f0f0f0;
  }

  
  .custom-item::part(native) {
    padding-top: -10px;
    padding-bottom: -10px;
  }