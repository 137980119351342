.search-business-modal::part(backdrop) {
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(10px);
  }
  
  .search-business-modal::part(content) {
    border-radius: 30px 30px 0 0;
    backdrop-filter: blur(25px);
    --background: rgba(255, 255, 255, 0.6);
  }
  

  .custom-list {
    background: transparent;
  }
  
  .custom-item {
    --background: transparent;
    --background-hover: rgba(0,0,0,0.05);
    --background-activated: rgba(0,0,0,0.1);
    margin-bottom: 10px;
    border-radius: 12px;
    
  }
  
  .custom-item::part(native) {
    padding-left: 0;
  }

  .custom-searchbar {
    --background: #fff;
    --background-hover: rgba(0,0,0,0.05);
    --background-activated: rgba(0,0,0,0.1);
    margin-bottom: 1px;
    border-radius: 12px;
  }
  